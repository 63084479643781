
export default {
  name: 'HeaderBlock',
  props: {
    design: {
      type: Object,
      default() {
        return {}
      }
    },
    designColor: {
      type: String,
      default: 'black'
    },
    isFixed: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    product_name() {
      return this.$store.state?.funnel?.config?.product_name || false
    },
    computed_styles() {
      return {
        'is-white': this?.design?.color === 'white',
        'is-fixed': this?.design['is-fixed'] || this.isFixed
      }
    },
    hidden() {
      const { design } = this
      return design && design.hidden
    }
  }
}
